import React, { useState } from "react";
import { Form, notification } from "antd";
import { ContReklama } from "context/context";
import { postFetch } from "utill/postFetch";

export default function ReklamaState({ children }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataReklama, setDataReklama] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, code, message) => {
    api[type]({
      message: code,
      description: message,
      duration: 3,
    });
  };

  function onAddReklama(value) {
    setLoading(true);
    value = {
      ...value,
      start: value["start"].format("DD-MM-YYYY"),
      end: value["end"].format("DD-MM-YYYY"),
    };
    postFetch({ path: "reklama", method: "POST", value })
      .then((response) => {
        if (response.status === 200) {
          getReklama();
          openNotificationWithIcon(
            "success",
            response.status,
            "Reklama saqlandi"
          );
          form.resetFields();
        } else {
          openNotificationWithIcon(
            "error",
            response.status,
            "Malumotlarda xatolik"
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  function getReklama() {
    setLoading(true);
    postFetch({ path: "reklama", method: "GET" })
      .then((response) => {
        setDataReklama(response.data);
      })
      .catch((error) => {
        openNotificationWithIcon(
          "error",
          error.status,
          "Malumotlarni yuklashda xatolik!!!!"
        );
      })
      .finally(() => setLoading(false));
  }

  function editReklama(value) {
    setLoading(true);
    value = {
      ...value,
      start: value["start"].format("DD-MM-YYYY"),
      end: value["end"].format("DD-MM-YYYY"),
    };
    postFetch({ path: `reklama/${value["id"]}`, method: "PUT", value })
      .then((response) => {
        if (response.status === 200) {
          getReklama();
          //   const filterReklama = dataReklama.filter(
          //     (rek) => rek.id !== value["id"]
          //   );
          //   setDataReklama([...filterReklama, value]);

          openNotificationWithIcon(
            "success",
            response.status,
            "Malumot taxrirlandi"
          );
        }
      })
      .catch((error) => {
        openNotificationWithIcon(
          "error",
          error.status,
          "Malumot taxrirlanishida xatolik"
        );
      })
      .finally(() => setLoading(false));
  }

  function deleteReklama(id) {
    setLoading(true);
    postFetch({ path: `reklama/${id}`, method: "DELETE" })
      .then((response) => {
        if (response.status === 200) {
          const filterReklama = dataReklama.filter((rek) => rek.id !== id);
          setDataReklama(filterReklama);
          openNotificationWithIcon(
            "success",
            response.status,
            "Malumot  uchirildi"
          );
        }
      })
      .catch((error) => {
        openNotificationWithIcon(
          "error",
          error.status,
          "Malumotni uchirishda xatolik"
        );
      })
      .finally(() => setLoading(false));
  }

  const value = {
    onAddReklama,
    contextHolder,
    form,
    openNotificationWithIcon,
    getReklama,
    loading,
    dataReklama,
    editReklama,
    deleteReklama,
  };

  return <ContReklama.Provider value={value}>{children}</ContReklama.Provider>;
}
