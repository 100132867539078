import { Col, DatePicker, Form, Input, Row, Card, Button, Upload } from "antd";

const { Item } = Form;

export default function ReklamaFormItem({
  form,
  formSubmit,
  uploadButton,
  upload,
}) {
  return (
    <Card>
      <Form layout="vertical" form={form} onFinish={formSubmit}>
        <Item name="id" hidden>
          <Input />
        </Item>

        <Row justify="space-between" gutter={12}>
          <Col span={12}>
            <Item name="url" label="URL link" required>
              <Input type="url" />
            </Item>
            <Item name="start" label="Reklama quyilgan sana" required>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Boshlangich sana"
              />
            </Item>
            <Item name="end" label="Reklama tugash sana" required>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Tugash sanasi"
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="text" label="Reklamanin qizqacha nomi" required>
              <Input.TextArea rows={2} maxLength={100} showCount />
            </Item>

            <Item name="img" label="Rasm" valuePropName="picture">
              <Upload
                accept="image/*"
                {...upload}
                listType="picture-card"
                progress
                maxCount={1}
                className="ant-upload-ant-upload-select"
              >
                {uploadButton}
              </Upload>
            </Item>
          </Col>
        </Row>
        <Row justify="end">
          <Item>
            <Button htmlType="submit" type="primary">
              Saqlash
            </Button>{" "}
            <Button htmlType="reset" type="primary" danger>
              Bekor qilish
            </Button>
          </Item>
        </Row>
      </Form>
    </Card>
  );
}
