import React from "react";
import css from "../../styles/Reklama.module.css";
import ReklamaFormItem from "./ReklamaFormItem";

export default function ReklamaForm({
  form,
  formSubmit,
  uploadButton,
  upload,
}) {
  function onDetails(e) {
    console.log(e);
  }

  return (
    <details onClick={onDetails} className={css.reklamaDetails}>
      <summary className={css.reklamaAdd}>Reklama qo`shish</summary>
      <ReklamaFormItem
        form={form}
        formSubmit={formSubmit}
        uploadButton={uploadButton}
        upload={upload}
      />
    </details>
  );
}
