import { useState } from "react";
import ReklamaForm from "components/reklama/ReklamaForm";
import { DB_Config } from "configs/urlConfig";
import { getCookie } from "utill/setCookie";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Divider, Image, Table } from "antd";
import useReklama from "state/reklama/useReklama";
import noImage from "../img/noimage.png";
import { Button, Drawer } from "antd";
import ReklamaFormItem from "components/reklama/ReklamaFormItem";
import dayjs from "dayjs";

export default function AppReklama() {
  const {
    onAddReklama,
    contextHolder,
    form,
    openNotificationWithIcon,
    loading,
    dataReklama,
    editReklama,
    deleteReklama,
  } = useReklama();

  const [open, setOpen] = useState(false);
  const showDrawer = (data) => {
    setOpen(true);
    onEdit(data);
  };
  const onClose = () => {
    setOpen(false);
    setEd();
  };

  const props = {
    method: "POST",
    name: "image",
    action: DB_Config.specialImageUrl,
    headers: {
      authorization: "Bearer" + " " + getCookie("access_token_admin"),
    },

    onChange({ file }) {
      if (file.response?.status === "success") {
        // console.log(file.response.data.image)
        form.setFieldsValue({
          img: file.response.data.image,
        });
        openNotificationWithIcon("success", " Yuklandi", `${file.name}`);
      } else if (file.status === "error") {
        openNotificationWithIcon("error", " Yuklanmadi", `${file.name}`);
      }
    },

    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const uploadButton = (
    <div style={{ width: "400px" }}>
      <CloudUploadOutlined />
      <p className="ant-upload-text">Format JPG, PNG, JPEG</p>
      <p className="ant-upload-hint">Rasimni tanlang</p>
    </div>
  );

  const column = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Rasm",
      dataIndex: "img",
      key: "img",
      width: 50,
      render: (_, rec) => {
        return rec.image !== null ? (
          <Image
            width={180}
            src={DB_Config.specialImage + rec.img}
            alt={"img"}
          />
        ) : (
          <Image width={180} src={noImage} alt={"img"} />
        );
      },
    },
    {
      title: "Reklamanin qizqacha nomi ",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Reklama quyilgan sana ",
      dataIndex: "start",
      key: "start",
    },
    {
      title: "Reklama tugash sana",
      dataIndex: "end",
      key: "end",
    },
    {
      title: "Manzil",
      dataIndex: "url",
      key: "url",
      render: (_, rec) => {
        return (
          <a target="_blank" href={rec.url}>
            URL manzili
          </a>
        );
      },
    },
    {
      title: "amallar",
      render: (_, rec) => {
        return (
          <>
            <Button type="primary" onClick={() => showDrawer(rec)}>
              <EditOutlined />
            </Button>
            <Drawer
              title="Taxrirlash"
              size="large"
              placement="right"
              onClose={onClose}
              open={open}
            >
              <ReklamaFormItem
                form={form}
                formSubmit={editReklama}
                uploadButton={uploadButton}
                upload={props}
                rekId={rec.id}
              />
            </Drawer>{" "}
            <Button type="primary" danger onClick={() => deleteReklama(rec.id)}>
              <DeleteOutlined />
            </Button>{" "}
          </>
        );
      },
    },
  ];
  const onEdit = (res) => {
    var endDate = dayjs(res.endDate);

    form.setFieldsValue({
      text: res.text,
      url: res.url,
      img: res.img,
      end: endDate,
      start: dayjs(res.start),
      id: res.id,
    });
  };
  const setEd = () => {
    form.setFieldsValue({
      text: "",
      url: "",
      img: "",
      start: "",
      end: "",
      id: "",
    });
  };

  return (
    <div>
      {contextHolder}
      <ReklamaForm
        form={form}
        formSubmit={onAddReklama}
        uploadButton={uploadButton}
        upload={props}
      />
      <Divider>Barcha Reklamalar</Divider>
      <Table
        rowKey={(row) => row.id}
        loading={loading}
        dataSource={dataReklama}
        columns={column}
      />
    </div>
  );
}
