import "./index.css";
import { Route, Routes } from "react-router-dom";
import AppSpecial from "./page/AppSpecial";
import AppUsers from "./page/AppUsers";
import AppComplaints from "./page/AppComplaints";
import AppAuthorization from "./page/AppAuthorization";
import AppLayout from "./page/AppLayout";
import AppUserAgreements from "./page/AppUserAgreements";
import AppUserCatousel from "./page/AppUserCarousel";
import AppReklama from "page/AppReklama";
import useReklama from "state/reklama/useReklama";
import { useEffect } from "react";

function App() {
  const { getReklama } = useReklama();

  useEffect(() => {
    getReklama();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<AppUsers />} />
        <Route path={"/category"} element={<AppSpecial />} />
        <Route path={"/complaints"} element={<AppComplaints />} />
        <Route path={"/argument"} element={<AppUserAgreements />} />
        <Route path={"/topUsers"} element={<AppUserCatousel />} />
        <Route path={"/reklama"} element={<AppReklama />} />
      </Route>
      <Route path="/login" element={<AppAuthorization />} />
    </Routes>
  );
}

export default App;
