import React, { useEffect, useState } from "react";
import { Form, Image, notification } from "antd";
import { postFetch } from "../utill/postFetch";
import { MainTable } from "../components/MainTable/MainTable";
import CategoryForm from "../components/category/CategoryForm";
import SubSpesial from "../components/subspecial/SubSpesial";
import { CloudUploadOutlined } from "@ant-design/icons";
import { getCookie } from "../utill/setCookie";
import noImage from "../img/noimage.png";
import { DB_Config } from "configs/urlConfig";

function AppSpecial() {
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, code, message) => {
    api[type]({
      message: code,
      description: message,
      duration: 3,
    });
  };

  const [special, setSpesial] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [cateID, setId] = useState();
  const [form] = Form.useForm();
  const fetchSpecial = () => {
    setIsLoading(true);
    postFetch({ path: "special", method: "GET" })
      .then((res) => {
        if (res.status === 200) {
          setSpesial(res.data.special);
          setIsLoading(false);
        } else {
          openNotificationWithIcon("error", res.code, res.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(err.message);
        openNotificationWithIcon("error", err.code, err.message);
      });
  };
  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Rasim",
      dataIndex: "image",
      width: 200,
      render: (_, rec) => {
        return rec.image !== null ? (
          <Image
            width={180}
            src={DB_Config.specialImage + rec.image}
            alt={"img"}
          />
        ) : (
          <Image width={180} src={noImage} alt={"img"} />
        );
      },
    },
    {
      title: "Kategoriya nomlari uzb.",
      dataIndex: "name",
      ket: "name",
    },
    {
      title: "Kategoriya nomlari russ.",
      dataIndex: "nameru",
      ket: "nameru",
    },
  ];
  const onEdit = (res) => {
    form.setFieldsValue({
      name: res.name,
      nameru: res.nameru,
      image: res.image,
    });
    setId(res.id);
  };
  const setEd = () => {
    form.setFieldsValue({
      name: "",
      nameru: "",
      image: "",
    });
  };

  function onDelete(_, key) {
    setIsLoading(true);
    postFetch({ path: "delete-special", value: { special_id: key.id } })
      .then((res) => {
        if (res.status === 200) {
          fetchSpecial();
          setIsLoading(false);
          openNotificationWithIcon("success", "O'chirildi");
        } else {
          openNotificationWithIcon("error", res.code, res.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        openNotificationWithIcon("error", err.code, err.message);
        setIsLoading(false);
      });
  }

  function onCategoryEdit(val) {
    setIsLoading(true);
    val.special_id = Number(cateID);
    val.description = "";
    postFetch({ path: "update-special", value: val })
      .then((res) => {
        if (res.status === 200) {
          fetchSpecial();
          setIsLoading(false);
          openNotificationWithIcon("success", "O'zgartirildi");
        } else {
          openNotificationWithIcon("error", res.code, res.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        openNotificationWithIcon("error", err.code, err.message);
        setIsLoading(false);
      });
  }

  function addCategory(val) {
    setIsLoading(true);
    val.description = "";
    // console.log(val)
    postFetch({ path: "insert-special", value: val })
      .then((res) => {
        if (res.status === 200) {
          fetchSpecial();
          setIsLoading(false);
          openNotificationWithIcon("success", "Qo'shildi");
        } else {
          openNotificationWithIcon("error", res.code, res.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        openNotificationWithIcon("error", err.code, err.message);
        setIsLoading(false);
      });
  }

  const props = {
    method: "POST",
    name: "image",
    action: DB_Config.specialImageUrl,
    headers: {
      authorization: "Bearer" + " " + getCookie("access_token_admin"),
    },
    onChange({ file }) {
      if (file.response?.status === "success") {
        // console.log(file.response.data.image)
        form.setFieldsValue({
          image: file.response.data.image,
        });
        openNotificationWithIcon("success", " Yuklandi", `${file.name}`);
      } else if (file.status === "error") {
        openNotificationWithIcon("error", " Yuklanmadi", `${file.name}`);
      }
    },

    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const uploadButton = (
    <div style={{ width: "100%" }}>
      <CloudUploadOutlined />
      <p className="ant-upload-text">Format JPG, PNG, JPEG</p>
      <p className="ant-upload-hint">Rasimni tanlang</p>
    </div>
  );

  useEffect(() => {
    fetchSpecial();
    //eslint-disable-next-line
  }, []);

  if (isLoading) return "Loading...";
  if (isError) return "Ma`lumot yuklanmadi: " + isError;
  return (
    <div>
      {contextHolder}
      <MainTable
        pdata={special}
        pcolumns={columns}
        pageTitle="Mutaxasisliklar"
        drawerOneTitle="Kategoriya qo'shish"
        drawerThwoTitle="Ma'lumotni taxrirlash"
        add={
          <CategoryForm
            form={form}
            dataUpload={props}
            onFinish={addCategory}
            uploadButton={uploadButton}
            buttonTitle="Qo`shish"
          />
        }
        edit={
          <CategoryForm
            buttonTitle="O`zgartirish"
            onFinish={onCategoryEdit}
            form={form}
            cateID={cateID}
            dataUpload={props}
            uploadButton={uploadButton}
          />
        }
        onEdit={onEdit}
        setEd={setEd}
        onDelete={onDelete}
        expandedRowRender={(record) => (
          <SubSpesial
            id={record.id}
            openNotificationWithIcon={openNotificationWithIcon}
          />
        )}
      />
    </div>
  );
}

export default AppSpecial;
