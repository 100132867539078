import axios from "axios";
import { getCookie } from "./setCookie";
import { DB_Config } from "configs/urlConfig";

export async function postFetch({ path, method = "POST", value = "" }) {
  const config = {
    method: method,
    url: `${DB_Config.dbUrl + path}`,
    headers: {
      "Content-Type": "application/json",
      // eslint-disable-next-line no-useless-concat
      Authorization: "Bearer" + " " + getCookie("access_token_admin"),
    },
    data: JSON.stringify(value),
  };
  // console.log("postFetch:", config);
  return axios(config)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}
