import React, {useEffect, useState} from 'react';
import {MainTable} from "../MainTable/MainTable";
import {Image, notification,  Spin, Tag} from "antd";
import noImage from "../../img/noimage.png";
import {postFetch} from "../../utill/postFetch";
const imgUrl = process.env.REACT_APP_IMAGE_URL
function UserCarouselTable() {
	// сообщение об успешном изминения данных
	const [api, contextHolder] = notification.useNotification();
	const openNotificationWithIcon = (type, code, message) => {
		api[type]({
			message: code,
			description: message,
			duration: 3,
		});
	};
	const [topUsers, setTopUser] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		postFetch({path: "topusers", method: "GET"}).then((res) => {
			if (res.status === 200) {
				setTopUser(res.data.data)
				// console.log("res.data.data", res.data.data)
			} else {
				openNotificationWithIcon(
					"warning",
					"Top dagi yoydalanuvchilarni yuklab bo'lmadi"
				);
			}
		}).catch((err) => {
			openNotificationWithIcon(
				"warning",
				"Top dagi yoydalanuvchilarni yuklab bo'lmadi"
			);
		}).finally(()=>{
			setLoading(false)
		})
		// 	eslint-disable-next-line
	}, []);

	const onDeleteTop = (e, rec) => {
		const value = {id: rec.id, top: 0}
		postFetch({path: "settop", value}).then((res) => {
			if(res.status === 200){
				setTopUser(topUsers.filter((item)=> item.id !== rec.id))
				openNotificationWithIcon(
					"success",
					"Top dan chiqarildi"
				);
			}

		}).catch((err) => {
			openNotificationWithIcon("error", err.code, err.message);
		})
		console.log(value)
	}

	const columns = [
		{
			title: "№",
			dataIndex: "id",
			key: "id",
			width: 50,
			render: (_, record, index) => {
				return index + 1
			},
		},
		{
			title: "Rasmi",
			dataIndex: "image",
			width: 200,
			render: (_, rec) => {
				let color = rec.status === 0 ? "#d9d9d9" : rec.status === 1 ? "#ff4d4f" : "#52c41a"
				let text = rec.status === 0 ? "blocklangan" : rec.status === 1 ? "kutilmoqda" : "tastiqlangan"
				const topColor = rec.top === 0 ? "#d9d9d9" : rec.top === 1 ? "#ff4d4f" : "#52c41a"
				const topText = rec.top === 1 ? "Top" : null
				const img = rec.image !== null ? <Image width={100} src={imgUrl + rec.image} alt={"img"}/> :
					<Image width={100} src={noImage} alt={"img"}/>
				return <div className="user__image-block" >
					{img}
					<Tag color={color}>{text}</Tag>
					<Tag color={topColor}>{topText}</Tag>
				</div>
			}
		},
		{
			title: "I.F.Sh",
			dataIndex: 'firstname',
			render: (_, rec) => {
				const firstname = rec.firstname !== null ? rec.firstname :	<Tag color="#f50">no data</Tag>
				const lastName = rec.lastname !== null ? rec.lastname :<Tag color="#f50">no data</Tag>
				return firstname + " " + lastName
			}
		},
		{
			title: 'Mansabi',
			dataIndex: 'role_id',
			render: (_, rec) => <>{(rec.role_id === 2) ? "Buyurtmachi" : (rec.role_id === 3) ? "Mutaxasis" : "Administrator"}</>
		},
		{
			title: "Telefon",
			dataIndex: "phone",
			render: (_, rec) => {
				return "+" + rec.phone
			}
		}

	]
	if(!topUsers){
		return (<h1>Ma`lumotlar yuklanmadi, serverda xatolik</h1>)
	}
	return (
		<>
			{contextHolder}
			<Spin spinning={loading}>
				<MainTable pageTitle="Top da" pcolumns={columns} pdata={topUsers} onDelete={onDeleteTop} />
			</Spin>
		</>
	);
};

export default UserCarouselTable;
